import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import ForgotPassword from "./components/pages/ForgotPassword";
import ChangePassword from "./components/pages/ChangePassword";
import Dashboard from "./components/pages/Dashboard";
import Apps from "./components/pages/apps/Applist";
import AddApp from "./components/pages/apps/AddApp";
import EditApp from "./components/pages/apps/EditApp";
import Builds from "./components/pages/builds/BuildList";
import AddBuild from "./components/pages/builds/AddBuild";
import BuildDetails from "./components/pages/builds/BuildDetails";
import Products from "./components/pages/product/Products";
import AddProduct from "./components/pages/product/AddProduct";
import EditProduct from "./components/pages/product/EditProduct";
import Categories from "./components/pages/category/Categories";
import Orders from "./components/pages/order/Orders";
import EditOrder from "./components/pages/order/EditOrder";
import Users from "./components/pages/user/Users";
import AddUser from "./components/pages/user/AddUser";
import UserDetails from "./components/pages/user/UserDetails";
import NotFound from "./components/pages/NotFound";
import AuthRoute from "./AuthRoute";
import PrivateRouter from "./PrivateRouter";
import Toast from "./components/LoadingError/Toast";
import UserPassword from "./components/pages/UserPassword";
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import VerifyPage from "./components/pages/Verify";
import LandingPage from "./components/pages/landingPage";
import Organizations from "./components/pages/Organization/Organizations";
import OrganizationDetails from "./components/pages/Organization/OrganizationDetails";
import CreateOrganization from "./components/pages/Organization/CreateOrganization";

function App() {
  const env_type = process.env.REACT_APP_ENVIRIONMENT;
  return (
    <>
      <Toast/>
      <Router>
        <Switch>
          <AuthRoute path="/landing" component={LandingPage} />
          <AuthRoute path="/login" component={Login} />
          <AuthRoute path="/register" component={Register} />
          <AuthRoute path="/Verify/:token" component={VerifyPage} />
          <AuthRoute path="/forgot-password" component={ForgotPassword} />
          <AuthRoute path="/change-password/:token" component={ChangePassword} />
          <PrivateRouter path="/dashboard" component={Dashboard} />
          <PrivateRouter path="/apps" component={Apps} />
          <PrivateRouter path="/app/add" component={AddApp} />
          <PrivateRouter path="/app/request" component={AddApp} />
          <PrivateRouter path="/app/edit/:id" component={EditApp} />
          <PrivateRouter path="/app/:id/builds" component={Builds} />
          <PrivateRouter path="/app/:id/build/add" component={AddBuild} />
          <PrivateRouter path="/app/:id/build/:buildId/view" component={BuildDetails} />
          <PrivateRouter path="/organizations" component={Organizations} />
          <PrivateRouter path="/organization/details/:id" component={OrganizationDetails} />
          <PrivateRouter path="/organization/create" component={CreateOrganization} />
          <PrivateRouter path="/organization/:organizationId/app/:id/builds" component={Builds} />
          <PrivateRouter path="/organization/:organizationId/app/:id/build/:buildId/view" component={BuildDetails} />
          {/* <PrivateRouter path="/products" component={Products} />
          <PrivateRouter path="/product/add" component={AddProduct} />
          <PrivateRouter path="/product/edit/:id" component={EditProduct} />
          <PrivateRouter path="/categories" component={Categories} />
          <PrivateRouter path="/orders" component={Orders} />
          <PrivateRouter path="/order/edit/:id" component={EditOrder} /> */}
          <PrivateRouter path="/users" component={Users} />
          <PrivateRouter path="/user/add" component={AddUser} />
          <PrivateRouter path="/user-password" component={UserPassword} />
          <PrivateRouter path="/user-details" component={UserDetails} />
          <PrivateRouter path="/user/edit/:id" component={AddUser} />
          <PrivateRouter path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
